import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatButton, MatDialogRef } from '@angular/material';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ToastrService } from 'ngx-toastr';
import { Globals } from 'src/app/libraries/globals';
import { EmpresasService } from 'src/app/services/empresas/empresas.service';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';

@Component({
  selector: 'comp-empresas-index-upload-logo',
  templateUrl: './upload-logo.component.html',
  styleUrls: ['./upload-logo.component.css']
})
export class EmpresasIndexUploadLogoComponent implements OnInit {

  @ViewChild('btnGuardar', {static:false})  btnGuardar  : MatButton;
  @ViewChild('btnCerrar', {static:false})   btnCerrar   : MatButton;

  @Output('onChange') onChange : EventEmitter<any> = new EventEmitter<any>();

  objData     : any;
  objDialogs  : Dialogs = new Dialogs();
  boolGuardar : boolean = false;

  // LOGO
  selectedImage     : boolean = false;
  imageChangedEvent : any = '';
  croppedImage      : any = '';
  extensionImage    : string = '.jpg';

  // PROGRESS BAR
  objProgres =
  {
    uploadProgress  : 0,
    error           : false,
    message         : ""
  }

  constructor(private objDialogRef : MatDialogRef<EmpresasIndexUploadLogoComponent>, 
              private objEmpresasService : EmpresasService,
              private toastr: ToastrService) { }

  ngOnInit() {
  }

  // EVENTOS
  close()
  {
    if(!this.boolGuardar)
    {
      this.objDialogRef.close();
    }
  }

  fileChangeEvent(event: any): void {
    if (event.target.files && event.target.files[0]) {

      var file = event.target.files[0];

      var mimeType = file.type;

      if (mimeType.match(/image\/*/) == null) {
        
        event.target.value = "";
        this.toastr.warning('Solo se admiten imagenes', 'Mensaje', { timeOut: 2000 });
        return;

      }

      var type : string = mimeType;

      this.imageChangedEvent  = event;
      this.extensionImage     = "." + type.substring(6)
      this.selectedImage      = true;
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  btnGuardar_clickEvent()
  {
    if(!this.selectedImage) {
      this.toastr.warning('No se ha cargado el logo', 'Mensaje', { timeOut: 2000 });
      return;
    }
    
    this.store();
  }

  // METODOS
  private loading(value : boolean) {
    if(value) {

      this.btnGuardar.disabled        = true;
      this.btnCerrar.disabled         = true;
      this.boolGuardar                = true;
      this.objDialogRef.disableClose  = true;

    } else{

      this.btnGuardar.disabled        = false;
      this.btnCerrar.disabled         = false;
      this.boolGuardar                = false;
      this.objDialogRef.disableClose  = false;

    }
  }

  private dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type:mimeString});
  }

  private store() : void {

    this.loading(true);

    var blobLogo = this.dataURItoBlob(this.croppedImage);

    let formInstructor = new FormData();

    formInstructor.append("idEmpresa",  this.objData.idEmpresa);
    formInstructor.append("logo",       blobLogo);
    formInstructor.append("extension",  this.extensionImage);

    this.objEmpresasService.uploadLogo(formInstructor)
    .subscribe( objResponse => {
      
      if(Globals.validValue(objResponse))
      {
        if(objResponse.status == 'completed'){
          
          if(objResponse.body.session) {
            
            
            if(objResponse.body.action) {
              
              console.log(objResponse)
              // Success
              this.objDialogRef.close();
              this.onChange.emit(this);
              this.objDialogs.Success(objResponse.body.title, objResponse.body.message);

            } else {

              // ERROR
              this.objProgres.uploadProgress  = 0;
              this.objProgres.error           = true;
              this.objProgres.message         = objResponse.body.message;
              this.loading(false);

            }

          } else {

            // ERROR
            this.objProgres.uploadProgress  = 0;
            this.objProgres.error           = true;
            this.objProgres.message         = objResponse.body.message;
            this.loading(false);

          }
          
        } else if(objResponse.status == 'progress') {

          // PROGRESS
          this.objProgres.uploadProgress = objResponse.uploadProgress;

        }
      }

    }, error => {

      // ERROR
      this.objProgres.uploadProgress  = 0;
      this.objProgres.error           = true;
      this.objProgres.message         = "Ha ocurrido un error inesperado, comuniquese con el administrador";
      this.loading(false);

    });
  }

}
