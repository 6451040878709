import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Sessions } from 'src/app/libraries/sessions';
import { IReturn } from 'src/app/libraries/ireturn';
import { APP_BASE_HREF } from '@angular/common';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FormasPagosService {

  constructor(private http: HttpClient,  @Inject(APP_BASE_HREF) private baseUrl: string) { }

  dataSource(varArgs : any = null): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Formas/Pagos/DataSource", varArgs, Sessions.header());
  }

  store(objServicioTipo : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Formas/Pagos/Store", objServicioTipo, Sessions.header());
  }

  update(objServicioTipo : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Formas/Pagos/Update", objServicioTipo, Sessions.header());
  }

  delete(objServicioTipo : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Formas/Pagos/Delete", objServicioTipo, Sessions.header());
  }

}