import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatButton, MatDialog, MatDialogRef, MatTableDataSource } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { ClientesContactosService } from 'src/app/services/clientesContactos/clientesContactos.service';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { Wait } from 'src/app/ui/wait/Wait';
import { ClientesContactosCreateComponent } from './create/create.component';

@Component({
  selector: 'app-clientes-contactos',
  templateUrl: './contactos.component.html',
  styleUrls: ['./contactos.component.css']
})
export class ClientesContactosComponent implements OnInit {

  @ViewChild('btnCerrar', {static:false})       btnCerrar       : MatButton;
  @ViewChild('tableContainer', {static: false}) tableContainer  : ElementRef;

  private boolGuardar : boolean = false;
  private objDialogs  : Dialogs = new Dialogs();
  private lstRows     : Array<any> = [];

  public objData  : any = null;

  // COLUMNS
  columns: string[] = ['index', 'opciones', 'nombreCompleto', 'telefono', 'correo', 'createdAspNetUser', 'createdFecha', 'updatedAspNetUser', 'updatedFecha'];

  // SEARCH FILTERS
  searchFilters : Array<any> = [{field : 'NombreCompleto'}, {field : 'Telefono'}, {field : 'Correo'}];
  
  dataSource : MatTableDataSource<any>;

  constructor(private objDialogRef : MatDialogRef<ClientesContactosComponent>, 
              private dialog: MatDialog,
              private objClientesContactosService : ClientesContactosService,
              private toastr: ToastrService) { }

  ngOnInit() {
    this.dataSource = new MatTableDataSource<any>();
  }

  ngAfterViewInit() {
    this.build();
  }

  // EVENTOS
  close()
  {
    if(!this.boolGuardar)
    {
      this.objDialogRef.close();
    }
  }

  btnNuevo_clickEvent() {
    
    const dialogRef = this.dialog.open(ClientesContactosCreateComponent, {
      width     : '99%',
      height    : '99%',
      maxWidth  : '400px',
      maxHeight : '350px'
    });

    dialogRef.componentInstance.objData = this.objData;

    dialogRef.componentInstance.onChange.subscribe(() => {
      this.build();
    });
    
  }

  btnEdit_clickEvent(element : any) {
    
    const dialogRef = this.dialog.open(ClientesContactosCreateComponent, {
      width     : '99%',
      height    : '99%',
      maxWidth  : '400px',
      maxHeight : '350px'
    });

    dialogRef.componentInstance.objData = element;
    dialogRef.componentInstance.isEdit  = true;

    dialogRef.componentInstance.onChange.subscribe(() => {
      this.build();
    });

  }

  btnDelete_clickEvent(element : any) {
    
    this.objDialogs.Confirm("Eliminar", "¿Eliminar contacto "+ element.nombreCompleto +"?", "Eliminar", "Cancelar", ()=> {
      this.delete(element.idClienteContacto);
    });

  }

  // METODOS
  private loading(value : boolean) {
    if(value) {

      this.btnCerrar.disabled         = true;
      this.boolGuardar                = true;
      this.objDialogRef.disableClose  = true;

    } else{

      this.btnCerrar.disabled         = false;
      this.boolGuardar                = false;
      this.objDialogRef.disableClose  = false;

    }
  }

  private build() : void {

    let varArgs : any = {
      search                    : "",
      searchFilters             : this.searchFilters,
      filters                   : [],
      dateFrom                  : "",
      dateTo                    : "",
      dateOptions               : [{field : "CreatedFecha"}],
      columns                   : [],
      persistenceColumns        : [],
      length                    : 500,
      page                      : 1,
      idCliente                 : this.objData.idCliente
    };

    Wait.show(this.tableContainer, "Cargando datos...");

    this.objClientesContactosService.dataSource(varArgs)
    .subscribe( objResponse => {

      if(objResponse.session) {

        if(objResponse.action) {

          this.lstRows = objResponse.result.rows;

          this.dataSource = new MatTableDataSource(this.lstRows);

        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }

      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }
      
      Wait.hide(this.tableContainer);
      
    }, error => {
      Wait.hide(this.tableContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
    
  }

  private delete(idClienteContacto : any) : void {
    
    Wait.show(this.tableContainer, "Eliminando...");
    this.loading(true);

    let objClienteContacto : any =
    {
      idClienteContacto : idClienteContacto
    };

    this.objClientesContactosService.delete(objClienteContacto)
    .subscribe( objResponse => {
    
      if(objResponse.session) {

        if(objResponse.action) {
          
          this.build();
          this.objDialogs.Success(objResponse.title, objResponse.message);
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      Wait.hide(this.tableContainer);
      this.loading(false);

    }, error => {

      Wait.hide(this.tableContainer);
      this.loading(false);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
  }

}
