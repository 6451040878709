import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormGroupDirective, FormGroup, FormControl, Validators } from '@angular/forms';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { ClientesService } from 'src/app/services/clientes/clientes.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Wait } from 'src/app/ui/wait/Wait';
import { MatCheckboxChange } from '@angular/material';

@Component({
  selector: 'comp-clientes-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css']
})
export class ClientesCreateComponent implements OnInit, AfterViewInit {

  @ViewChild('mainContainer', {static: false})  mainContainer : ElementRef;
  @ViewChild('ngForm', {static: false})         ngForm        : FormGroupDirective;

  private objDialogs  : Dialogs = new Dialogs();
  private form        : FormGroup;

  private lstEstados : Array<any> = [];

  constructor(private objClientesService : ClientesService,
              private toastr: ToastrService,
              private router: Router) { }

  ngOnInit() {

    this.form = new FormGroup({
      'codigo'        : new FormControl(null, [Validators.required]),
      'name'          : new FormControl(null, [Validators.required]),
      'razonSocial'   : new FormControl(null, [Validators.required]),
      'rfc'           : new FormControl(null),
      'telefono'      : new FormControl(null),
      'celular'       : new FormControl(null),
      'email'         : new FormControl(null),
      'direccion'     : new FormControl(null),
      'ciudad'        : new FormControl(null),
      'estado'        : new FormControl(null),
      'codigoPostal'  : new FormControl(null)
    });

  }

  ngAfterViewInit() {
    this.create();
  }

  //EVENTOS
  btnGuardar_clickEvent() {
    
    if(this.form.invalid) {
      this.toastr.warning('Datos incompletos en el formulario', 'Formulario', { timeOut: 2000 });
      this.ngForm.onSubmit(undefined);
      return;
    }

    this.store();

  }

  // METODOS
  private create() : void {

    Wait.show(this.mainContainer, "Espere por favor...");

    this.objClientesService.create()
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {

          this.lstEstados = objResponse.result.estados;
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      Wait.hide(this.mainContainer);

    }, error => {

      Wait.hide(this.mainContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

  private store() : void {

    Wait.show(this.mainContainer, "Guardando...");

    let objClienteStore : any =
    {
      codigo        : this.form.controls["codigo"].value,
      name          : this.form.controls["name"].value,
      razonSocial   : this.form.controls["razonSocial"].value,
      rfc           : this.form.controls["rfc"].value,
      telefono      : this.form.controls["telefono"].value,
      celular       : this.form.controls["celular"].value,
      email         : this.form.controls["email"].value,
      direccion     : this.form.controls["direccion"].value,
      ciudad        : this.form.controls["ciudad"].value,
      idEstado      : this.form.controls["estado"].value,
      codigoPostal  : this.form.controls["codigoPostal"].value
    };

    this.objClientesService.store(objClienteStore)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {

          this.objDialogs.Success(objResponse.title, objResponse.message, null, ()=> {
            this.router.navigate(["/dashboard/refresh", "clientes/create"]);
          });
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      Wait.hide(this.mainContainer);

    }, error => {

      Wait.hide(this.mainContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

}
