import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatButton, MatDialogRef } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { CotizacionesCondicionesService } from 'src/app/services/cotizacionesCondiciones/cotizacionesCondiciones.service';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { Wait } from 'src/app/ui/wait/Wait';

@Component({
  selector: 'app-condiciones-tipos-descripciones-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css']
})
export class CondicionesTiposDescripcionesCreateComponent implements OnInit {

  @ViewChild('mainContainer', {static:false}) mainContainer : ElementRef;
  @ViewChild('btnGuardar', {static:false})    btnGuardar    : MatButton;
  @ViewChild('btnCerrar', {static:false})     btnCerrar     : MatButton;
  @ViewChild('ngFormGroup', {static:false})   ngFormGroup   : FormGroupDirective;

  @Output('onChange') onChange : EventEmitter<any> = new EventEmitter<any>();
  
  private boolGuardar : boolean = false;
  private objDialogs  : Dialogs = new Dialogs();
  private formGroup   : FormGroup;

  public objData  : any = null;
  public isEdit   : boolean = false;

  constructor(private objDialogRef : MatDialogRef<CondicionesTiposDescripcionesCreateComponent>, 
              private objCotizacionesCondicionesService : CotizacionesCondicionesService,
              private toastr: ToastrService) { }

  ngOnInit() {
    
    this.formGroup = new FormGroup({
      'descripcion' : new FormControl(null, [Validators.required])
    });

    if(this.isEdit) {
      this.formGroup.controls["descripcion"].setValue(this.objData.descripcion);
    }

  }

  // EVENTOS
  close()
  {
    if(!this.boolGuardar)
    {
      this.objDialogRef.close();
    }
  }

  btnGuardar_clickEvent()
  {
    if(this.formGroup.invalid) {
      this.toastr.warning('Datos incompletos en el formulario', 'Formulario', { timeOut: 2000 });
      this.ngFormGroup.onSubmit(undefined);
      return;
    }
    
    if(this.isEdit)
    {
      this.update();
    } else {
      this.store();
    }
  }

  // METODOS
  private loading(value : boolean) {
    if(value) {

      Wait.show(this.mainContainer, "Guardando...");
      this.btnGuardar.disabled        = true;
      this.btnCerrar.disabled         = true;
      this.boolGuardar                = true;
      this.objDialogRef.disableClose  = true;

    } else{

      Wait.hide(this.mainContainer);
      this.btnGuardar.disabled        = false;
      this.btnCerrar.disabled         = false;
      this.boolGuardar                = false;
      this.objDialogRef.disableClose  = false;

    }
  }

  private store() : void {

    this.loading(true);

    let objCotizacionCondicionStore : any =
    {
      idCotizacionCondicionTipo : this.objData.idCotizacionCondicionTipo,
      descripcion               : this.formGroup.controls["descripcion"].value
    };

    this.objCotizacionesCondicionesService.store(objCotizacionCondicionStore)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {
          
          this.objDialogRef.close();
          this.onChange.emit(objResponse.result);
          this.objDialogs.Success(objResponse.title, objResponse.message);
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      this.loading(false);

    }, error => {

      this.loading(false);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

  private update() : void {

    this.loading(true);

    let objCotizacionCondicionUpdate : any =
    {
      idCotizacionCondicion : this.objData.idCotizacionCondicion,
      descripcion           : this.formGroup.controls["descripcion"].value
    };

    this.objCotizacionesCondicionesService.update(objCotizacionCondicionUpdate)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {

          this.onChange.emit(this);
          this.objDialogs.Success(objResponse.title, objResponse.message);
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      this.loading(false);

    }, error => {

      this.loading(false);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

}
