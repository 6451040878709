import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatButton, MatDialogRef, MatSelectChange } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { CotizacionesService } from 'src/app/services/cotizaciones/cotizaciones.service';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { Wait } from 'src/app/ui/wait/Wait';

@Component({
  selector: 'comp-cotizaciones-index-aprobar-cancelar',
  templateUrl: './aprobar-cancelar.component.html',
  styleUrls: ['./aprobar-cancelar.component.css']
})
export class CotizacionesIndexAprobarCancelarComponent implements OnInit {

  @ViewChild('mainContainer', {static:false}) mainContainer : ElementRef;
  @ViewChild('btnGuardar', {static:false})    btnGuardar    : MatButton;
  @ViewChild('btnCerrar', {static:false})     btnCerrar     : MatButton;
  @ViewChild('ngForm', {static: false})       ngForm        : FormGroupDirective;
  
  @Output('onChange') onChange : EventEmitter<any> = new EventEmitter<any>();

  private boolGuardar : boolean = false;
  private objDialogs  : Dialogs = new Dialogs();
  private formGroup   : FormGroup;

  public objData : any = null;

  constructor(private objDialogRef : MatDialogRef<CotizacionesIndexAprobarCancelarComponent>, 
              private objCotizacionesService : CotizacionesService,
              private toastr: ToastrService) { }

  ngOnInit() {

    this.formGroup = new FormGroup({
      'opciones'                : new FormControl("", [Validators.required]),
      'comentariosCancelacion'  : new FormControl("", [Validators.required])
    });

    this.formGroup.controls["comentariosCancelacion"].disable();
    
  }

  // EVENTOS
  close()
  {
    if(!this.boolGuardar)
    {
      this.objDialogRef.close();
    }
  }
  
  selectedOpciones_selectedEvent(event : MatSelectChange)
  {
    if(event.value == 1) {
      this.formGroup.controls["comentariosCancelacion"].disable();
    } else if(event.value == 2) {
      this.formGroup.controls["comentariosCancelacion"].enable();
    } else {
      this.formGroup.controls["comentariosCancelacion"].disable();
    }
  }

  btnGuardar_clickEvent()
  {

    if(this.formGroup.invalid) {
      this.toastr.warning('Datos incompletos en el formulario', 'Mensaje', { timeOut: 2000 });
      this.ngForm.onSubmit(undefined);
      return;
    }

    var opcion = this.formGroup.controls["opciones"].value;

    if(opcion == 1)
    {
      this.aprobar()
    } else {
      this.cancelar();
    }

  }

  // METODOS
  private loading(value : boolean) {
    if(value) {

      Wait.show(this.mainContainer, "Guardando...");
      this.btnGuardar.disabled        = true;
      this.btnCerrar.disabled         = true;
      this.boolGuardar                = true;
      this.objDialogRef.disableClose  = true;

    } else{

      Wait.hide(this.mainContainer);
      this.btnGuardar.disabled        = false;
      this.btnCerrar.disabled         = false;
      this.boolGuardar                = false;
      this.objDialogRef.disableClose  = false;

    }
  }

  private aprobar() : void {

    let objCotizacion : any =
    {
      idCotizacion : this.objData.idCotizacion
    };

    this.loading(true);

    this.objCotizacionesService.aprobar(objCotizacion)
    .subscribe( objResponse => {
      
        if(objResponse.session) {

          if(objResponse.action) {
            
            this.onChange.emit(this);
            this.objDialogRef.close();
            this.objDialogs.Success(objResponse.title, objResponse.message);
            
          } else {
            this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
          }
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }

        this.loading(false);

    }, error => {

      this.loading(false);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

  private cancelar() : void {

    let objCotizacion : any =
    {
      idCotizacion : this.objData.idCotizacion,
      comentariosCancelacion : this.formGroup.controls["comentariosCancelacion"].value
    };

    this.loading(true);

    this.objCotizacionesService.cancelar(objCotizacion)
    .subscribe( objResponse => {
      
        if(objResponse.session) {

          if(objResponse.action) {
            
            this.onChange.emit(this);
            this.objDialogRef.close();
            this.objDialogs.Success(objResponse.title, objResponse.message);
            
          } else {
            this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
          }
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }

        this.loading(false);

    }, error => {

      this.loading(false);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

}
