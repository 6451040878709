import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApplicationServices } from './applicationConfig/application-services';
import { MAT_DATE_FORMATS, NativeDateModule, MatDatepickerModule, MatSnackBarModule, MatDialogModule, MatCardModule, MAT_DATE_LOCALE, MatPaginatorIntl, DateAdapter } from '@angular/material';
import { DatePipe, APP_BASE_HREF, CurrencyPipe } from '@angular/common';
import { AppMaterialModule } from './app-material/app-material.module';
import { ApplicationDeclarations } from './applicationConfig/application-declarations';
import { ApplicationEntryComponents } from './applicationConfig/application-entry-components';
import { AppRoutingModule } from './app-routing.module';
import { ScrollDispatchModule, ScrollingModule } from '@angular/cdk/scrolling';
import { CountdownModule } from 'ngx-countdown';
import { LightboxModule } from 'ngx-lightbox';
import { NgxUiLoaderModule, NgxUiLoaderConfig, SPINNER, POSITION, PB_DIRECTION, NgxUiLoaderRouterModule, NgxUiLoaderHttpModule } from 'ngx-ui-loader';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ToastrModule } from 'ngx-toastr';
import { ImageCropperModule } from 'ngx-image-cropper';

export const DATES_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MM YYYY',
  },
};

export const DD_MM_YYYY_Format = {
  parse: {
      dateInput: 'LL',
  },
  display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const DateFormat = {
  parse: {
  dateInput: 'input',
  },
  display: {
  dateInput: 'DD/MM/YYYY',
  monthYearLabel: 'MMMM YYYY',
  dateA11yLabel: 'DD/MM/YYYY',
  monthYearA11yLabel: 'MMMM YYYY',
 }
};

export class MatPaginatorIntlCro extends MatPaginatorIntl {
  itemsPerPageLabel  = "No. Registros";
  firstPageLabel     = "Primera página";
  nextPageLabel      = "Siguiente pagina";
  lastPageLabel      = "Última página";
  previousPageLabel  = "Página anterior";

  getRangeLabel = function (page, pageSize, length) {
    if (length === 0 || pageSize === 0) {
      return '0 de ' + length;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
  };

}

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  "bgsColor": "#001fff",
  "bgsOpacity": 0.6,
  "bgsPosition": "top-center",
  "bgsSize": 90,
  "bgsType": "ball-spin-fade-rotating",
  "blur": 8,
  "delay": 0,
  // "fastFadeOut": true,
  "fgsColor": "#3344a5",
  "fgsPosition": "center-center",
  "fgsSize": 60,
  "fgsType": "three-strings",
  "gap": 76,
  "logoPosition": "center-center",
  "logoSize": 120,
  "logoUrl": "",
  "masterLoaderId": "master",
  "overlayBorderRadius": "0",
  "overlayColor": "rgb(255,255,255)",
  "pbColor": "#3344a5",
  "pbDirection": "ltr",
  "pbThickness": 5,
  "hasProgressBar": true,
  "text": "Manoxi",
  "textColor": "#FFFFFF",
  "textPosition": "center-center",
  "maxTime": -1,
  "minTime": 300
};

@NgModule({
  entryComponents: ApplicationEntryComponents,
  declarations: ApplicationDeclarations,
  imports: [
    // A
    AppMaterialModule,
    AppRoutingModule,

    // B
    BrowserModule,    
    BrowserAnimationsModule,

    // C
    CountdownModule,

    // H
    HttpClientModule,
    
    // F
    FormsModule,
    FlexLayoutModule,

    // I
    ImageCropperModule,

    // L
    LightboxModule,
    
    // M
    MatCardModule,
    MatDialogModule,
    MatSnackBarModule,
    MatDatepickerModule,
    
    // N
    NativeDateModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    // NgxUiLoaderRouterModule,
    // NgxUiLoaderHttpModule,

    // R
    ReactiveFormsModule,

    // T
    ToastrModule.forRoot({
      timeOut: 5000,
      preventDuplicates: true,
      resetTimeoutOnDuplicate: true,
      tapToDismiss : true,
      closeButton : true,
      progressBar : true,
      progressAnimation : "increasing",
      positionClass: "toast-top-center"
    }),

    // S
    ScrollingModule,
    ScrollDispatchModule
  ],
  providers: 
  [ { provide: APP_BASE_HREF, useValue:"" },
    DatePipe,
    CurrencyPipe,
    // { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    {provide: MAT_DATE_LOCALE, useValue: "es-ES"},
    {provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro},
    // { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DateFormat },
    ApplicationServices
  ], 
  bootstrap: [AppComponent]
})
export class AppModule { }
