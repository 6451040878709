import { Component, EventEmitter, Inject, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect, MAT_BOTTOM_SHEET_DATA } from '@angular/material';

@Component({
  selector: 'app-filtros',
  templateUrl: './filtros.component.html',
  styleUrls: ['./filtros.component.css']
})
export class CotizacionesIndexFiltrosComponent implements OnInit {

  @ViewChildren('sltFilter') sltFilter : QueryList<MatSelect>;

  @Output("onChange") onChange : EventEmitter<any> = new EventEmitter<any>();

  // DATES FILTER
  public txtDateDesde  = new FormControl();
  public txtDateHasta  = new FormControl();

  // FILTERS
  public sltCotizacionEstatus  = new FormControl();
  public sltEmpresa            = new FormControl();
  public sltEstado             = new FormControl();
  public sltFormaPago          = new FormControl();
  public sltMetodoPago         = new FormControl();
  public sltCreatedAspNetUser  = new FormControl();
  public sltUpdatedAspNetUser  = new FormControl();

  public lstCotizacionesEstatus : Array<any> = [];
  public lstEmpresas            : Array<any> = [];
  public lstEstados             : Array<any> = [];
  public lstFormasPagos         : Array<any> = [];
  public lstMetodosPagos        : Array<any> = [];
  public lstCreatedAspNetUser   : Array<any> = [];
  public lstUpdatedAspNetUser   : Array<any> = [];


  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any) { }

  ngOnInit() {
    this.lstCotizacionesEstatus = this.data.lstCotizacionesEstatus;
    this.lstEmpresas            = this.data.lstEmpresas;
    this.lstEstados             = this.data.lstEstados;
    this.lstFormasPagos         = this.data.lstFormasPagos;
    this.lstMetodosPagos        = this.data.lstMetodosPagos;
    this.lstCreatedAspNetUser   = this.data.lstCreatedAspNetUser;
    this.lstUpdatedAspNetUser   = this.data.lstUpdatedAspNetUser;

    this.txtDateDesde.setValue(this.data.txtDateDesde);
    this.txtDateHasta.setValue(this.data.txtDateHasta);
    this.sltCotizacionEstatus.setValue(this.data.sltCotizacionEstatus);
    this.sltEmpresa.setValue(this.data.sltEmpresa);
    this.sltEstado.setValue(this.data.sltEstado);
    this.sltFormaPago.setValue(this.data.sltFormaPago);
    this.sltMetodoPago.setValue(this.data.sltMetodoPago);
    this.sltCreatedAspNetUser.setValue(this.data.sltCreatedAspNetUser);
    this.sltUpdatedAspNetUser.setValue(this.data.sltUpdatedAspNetUser);
  }

  // METODOS
  private build() : void {
    this.onChange.emit(this);
  }

}
