import { Routes } from '@angular/router';
import { LoginComponent } from '../login/login.component';
import { AuthGuard } from '../auth/auth.guard';
import { DashboardLayoutComponent } from '../dashboard/layout/dashboard-layout/dashboard-layout.component';
import { InicioComponent } from '../dashboard/contents/inicio/inicio.component';
import { RefreshComponent } from '../dashboard/contents/components/refresh/refresh.component';
import { ClientesIndexComponent } from '../dashboard/contents/clientes/index/index.component';
import { ClientesCreateComponent } from '../dashboard/contents/clientes/create/create.component';
import { UsuariosIndexComponent } from '../dashboard/contents/usuarios/index/index.component';
import { UsuariosCreateComponent } from '../dashboard/contents/usuarios/create/create.component';
import { ConfiguracionesGlobalComponent } from '../dashboard/contents/configuraciones/global/global.component';
import { CotizacionesIndexComponent } from '../dashboard/contents/cotizaciones/index/index.component';
import { CotizacionesCreateComponent } from '../dashboard/contents/cotizaciones/create/create.component';
import { CuentasBancariasIndexComponent } from '../dashboard/contents/cuentasBancarias/index/index.component';
import { EmpresasIndexComponent } from '../dashboard/contents/empresas/index/index.component';
import { FormasPagosIndexComponent } from '../dashboard/contents/formasPagos/index/index.component';
import { MetodosPagosIndexComponent } from '../dashboard/contents/metodosPagos/index/index.component';
import { ServiciosTiposIndexComponent } from '../dashboard/contents/serviciosTipos/index/index.component';
import { ServiciosTiposCreateComponent } from '../dashboard/contents/serviciosTipos/create/create.component';
import { CondicionesTiposIndexComponent } from '../dashboard/contents/condicionesTipos/index/index.component';

export const ApplicationRoute: Routes = [

    { path: '',           component: LoginComponent },
    { path: 'login',      component: LoginComponent },

    { path: 'dashboard',  component: DashboardLayoutComponent,  canActivate: [AuthGuard], children: [
          { path: '',                                 component: InicioComponent,                     canActivate: [AuthGuard] },
          
          // CLIENTES
          { path: 'clientes',                         component: ClientesIndexComponent,              canActivate: [AuthGuard] },
          { path: 'clientes/create',                  component: ClientesCreateComponent,             canActivate: [AuthGuard] },

          // CONFIGURACIONES
          { path: 'configuraciones/global',           component: ConfiguracionesGlobalComponent,      canActivate: [AuthGuard] },

          // COTIZACIONES
          { path: 'cotizaciones',                     component: CotizacionesIndexComponent,          canActivate: [AuthGuard] },
          { path: 'cotizaciones/create',              component: CotizacionesCreateComponent,         canActivate: [AuthGuard] },

          // COTIZACIONES TIPOS
          { path: 'condiciones/tipos',                component: CondicionesTiposIndexComponent,      canActivate: [AuthGuard] },

          // CUENTAS BANCARIAS
          { path: 'cuentas/bancarias',                component: CuentasBancariasIndexComponent,      canActivate: [AuthGuard] },

          // EMPRESAS
          { path: 'empresas',                         component: EmpresasIndexComponent,              canActivate: [AuthGuard] },

          // FORMAS PAGOS
          { path: 'formas/pagos',                     component: FormasPagosIndexComponent,           canActivate: [AuthGuard] },

          // METODOS PAGOS
          { path: 'metodos/pagos',                    component: MetodosPagosIndexComponent,          canActivate: [AuthGuard] },
          
          // RELOAD
          { path: 'refresh/:url',                     component: RefreshComponent,                    canActivate: [AuthGuard]},

          // SERVICIOS TIPOS
          { path: 'servicios',                        component: ServiciosTiposIndexComponent,        canActivate: [AuthGuard] },
          { path: 'servicios/create',                 component: ServiciosTiposCreateComponent,       canActivate: [AuthGuard] },

          // USUARIOS
          { path: 'usuarios',                         component: UsuariosIndexComponent,              canActivate: [AuthGuard] },
          { path: 'usuarios/create',                  component: UsuariosCreateComponent,             canActivate: [AuthGuard] },
          
          { path: '**',                               redirectTo: 'dashboard' },
    ]},

    { path: '**', redirectTo: '/', pathMatch : "full" }
  
  ];