import { Component, OnInit, ViewChild, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { MatPaginator, MatSelect, MatTableDataSource, MatDialog, MatBottomSheet } from '@angular/material';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { FormControl } from '@angular/forms';
import { ClientesService } from 'src/app/services/clientes/clientes.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { Sessions } from 'src/app/libraries/sessions';
import { Globals } from 'src/app/libraries/globals';
import { Wait } from 'src/app/ui/wait/Wait';
import { ClientesIndexEditComponent } from './edit/edit.component';
import { ClientesIndexFiltrosComponent } from './filtros/filtros.component';
import { ClientesContactosComponent } from '../contactos/contactos.component';

@Component({
  selector: 'comp-clientes-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class ClientesIndexComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false})    paginator       : MatPaginator;
  @ViewChild('tableContainer', {static: false}) tableContainer  : ElementRef;
  @ViewChildren('sltFilter')                    sltFilter       : QueryList<MatSelect>;

  private lstClientes : Array<any> = [];
  private objDialogs  : Dialogs = new Dialogs();
  private index       : number;

  // SEARCH
  private txtSearch = new FormControl();

  // DATES FILTER
  private txtDateDesde  = new FormControl();
  private txtDateHasta  = new FormControl();

  // FILTERS
  private sltCreatedAspNetUser  = new FormControl();
  private sltUpdatedAspNetUser  = new FormControl();

  private lstCreatedAspNetUser  : Array<any> = [];
  private lstUpdatedAspNetUser  : Array<any> = [];

  // COLUMNS
  columns: string[] = ['index', 'opciones', 'codigo', 'name', 'razonSocial', 'rfc', 'telefono', 'celular', 'email', 'direccion', 'createdAspNetUser', 'createdFecha', 'updatedAspNetUser', 'updatedFecha'];

  // SEARCH FILTERS
  searchFilters : Array<any> = [{field : 'Codigo'},
                                {field : 'Name'},
                                {field : 'RazonSocial'},
                                {field : 'RFC'},
                                {field : 'Telefono'},
                                {field : 'Celular'},
                                {field : 'Email'},
                                {field : 'Direccion'},
                                {field : 'Ciudad'},
                                {field : 'CodigoPostal'}];
  
  dataSource : MatTableDataSource<any>;

  private objPrivilegies : any;

  constructor(private objClientesService : ClientesService,
              private dialog: MatDialog,
              private toastr: ToastrService,
              private datePipe : DatePipe,
              private _bottomSheet: MatBottomSheet) { }

  ngOnInit() {
    this.objPrivilegies = Sessions.getItem('privilegies');
    this.initialization();
    this.dataSource = new MatTableDataSource<any>();
  }

  ngAfterViewInit() {
    this.build();
  }

  // EVENTOS
  btnFiltros_clickEvent() {
    
    var objData : any =
    {
      lstCreatedAspNetUser  : this.lstCreatedAspNetUser,
      lstUpdatedAspNetUser  : this.lstUpdatedAspNetUser,

      // FORM CONTROL
      txtDateDesde          : this.txtDateDesde.value,
      txtDateHasta          : this.txtDateHasta.value,
      sltCreatedAspNetUser  : this.sltCreatedAspNetUser.value,
      sltUpdatedAspNetUser  : this.sltUpdatedAspNetUser.value,
    }

    var bottomSheet = this._bottomSheet.open(ClientesIndexFiltrosComponent, {
      data : objData
    });

    bottomSheet.instance.onChange.subscribe((item: ClientesIndexFiltrosComponent) => {

      this.txtDateDesde.setValue(item.txtDateDesde.value);
      this.txtDateHasta.setValue(item.txtDateHasta.value);

      this.sltCreatedAspNetUser = item.sltCreatedAspNetUser;
      this.sltUpdatedAspNetUser = item.sltUpdatedAspNetUser;

      this.sltFilter.toArray()[0].value = this.sltCreatedAspNetUser.value;
      this.sltFilter.toArray()[1].value = this.sltUpdatedAspNetUser.value;

      this.build();
      
    });
  }

  btnEdit_clickEvent(element : any) {
    
    const dialogRef = this.dialog.open(ClientesIndexEditComponent, {
      width     : '99%',
      height    : '99%',
      maxWidth  : '800px',
      maxHeight : '500px'
    });

    dialogRef.componentInstance.objData = element;

    dialogRef.componentInstance.onChange.subscribe(() => {
      this.build();
    });

  }

  btnContactos_clickEvent(element : any) {
    const dialogRef = this.dialog.open(ClientesContactosComponent, {
      width     : '99%',
      height    : '99%',
      maxWidth  : '1000px',
      maxHeight : '900px'
    });

    dialogRef.componentInstance.objData = element;
  }

  btnDelete_clickEvent(element : any) {
    
    this.objDialogs.Confirm("Eliminar", "¿Eliminar cliente "+ element.name +"?", "Eliminar", "Cancelar", ()=> {
      this.delete(element.idCliente);
    });

  }

  // METODOS
  private initialization() : void {

    this.objClientesService.index()
    .subscribe( objResponse => {

      if(objResponse.session) {

        if(objResponse.action) {

          this.lstCreatedAspNetUser = objResponse.result.usuarios;
          this.lstUpdatedAspNetUser = objResponse.result.usuarios;

        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }

      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }
      
    }, error => {

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
  }
  
  private build() : void {

    let varArgs : any = {
      search              : Globals.validValue(this.txtSearch.value) ? this.txtSearch.value : "",
      searchFilters       : this.searchFilters,
      filters             : this.filters(),
      dateFrom            : Globals.validValue(this.txtDateDesde.value) ? this.datePipe.transform(this.txtDateDesde.value, 'yyyy-MM-dd') : "",
      dateTo              : Globals.validValue(this.txtDateHasta.value) ? this.datePipe.transform(this.txtDateHasta.value, 'yyyy-MM-dd') : "",
      dateOptions         : [{field : "CreatedFecha"}],
      columns             : [],
      persistenceColumns  : [],
      length              : !Globals.validValue(this.paginator.pageSize) ? 15 : this.paginator.pageSize,
      page                : this.paginator.pageIndex + 1
    };

    Wait.show(this.tableContainer, "Cargando datos...");

    this.objClientesService.dataSource(varArgs)
    .subscribe( objResponse => {

      if(objResponse.session) {

        if(objResponse.action) {

          this.lstClientes          = objResponse.result.rows;

          this.dataSource           = new MatTableDataSource(this.lstClientes);
          this.paginator.pageIndex  = objResponse.result.page - 1;
          this.paginator.pageSize   = objResponse.result.length;
          this.paginator.length     = objResponse.result.count;
          this.index                = this.paginator.pageIndex * this.paginator.pageSize + 1;

        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }

      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }
      
      Wait.hide(this.tableContainer);
      
    }, error => {
      Wait.hide(this.tableContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
    
  }

  private filters() {
    let lstFilters : Array<any> = [];

    if(Globals.validValue(this.sltFilter))
    {
      this.sltFilter.forEach(itemFilter => {

        if(Globals.validValue(itemFilter.value))
        {
          var filter : any = {
            field           : itemFilter.id,
            value           : itemFilter.value
          };

          lstFilters.push(filter);
        }

      })
    }

    return lstFilters;
  }

  private borrarFiltros() : void {
    this.txtSearch.setValue("");
    this.txtDateDesde.setValue("");
    this.txtDateHasta.setValue("");
    this.sltCreatedAspNetUser.setValue("");
    this.sltUpdatedAspNetUser.setValue("");
    
    this.paginator.pageIndex  = 0;
    this.paginator.pageSize   = 15;

    this.sltFilter.forEach(itemFilter => {
      itemFilter.value = "";
    });
    
    this.build();
  }

  private delete(idCliente : any) : void {
    
    Wait.show(this.tableContainer, "Eliminando...")

    let objClienteDelete : any =
    {
      idCliente : idCliente
    };

    this.objClientesService.delete(objClienteDelete)
    .subscribe( objResponse => {
    
      if(objResponse.session) {

        if(objResponse.action) {
          
          this.build();
          this.objDialogs.Success(objResponse.title, objResponse.message);
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      Wait.hide(this.tableContainer);

    }, error => {

      Wait.hide(this.tableContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
  }

}
