import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatBottomSheet, MatDialog, MatPaginator, MatSelect, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Globals } from 'src/app/libraries/globals';
import { Sessions } from 'src/app/libraries/sessions';
import { CotizacionesService } from 'src/app/services/cotizaciones/cotizaciones.service';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { Wait } from 'src/app/ui/wait/Wait';
import { CotizacionesIndexAprobarCancelarComponent } from './aprobar-cancelar/aprobar-cancelar.component';
import { CotizacionesIndexDuplicarComponent } from './duplicar/duplicar.component';
import { CotizacionesIndexEmailComponent } from './email/email.component';
import { CotizacionesIndexFiltrosComponent } from './filtros/filtros.component';

@Component({
  selector: 'comp-cotizaciones-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class CotizacionesIndexComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false})    paginator       : MatPaginator;
  @ViewChild('tableContainer', {static: false}) tableContainer  : ElementRef;
  @ViewChildren('sltFilter')                    sltFilter       : QueryList<MatSelect>;

  private lstCotizaciones : Array<any> = [];
  private objDialogs      : Dialogs = new Dialogs();
  private index           : number;

  // SEARCH
  private txtSearch = new FormControl();

  // DATES FILTER
  private txtDateDesde  = new FormControl();
  private txtDateHasta  = new FormControl();

  // FILTERS
  private sltCotizacionEstatus  = new FormControl();
  private sltEmpresa            = new FormControl();
  private sltEstado             = new FormControl();
  private sltFormaPago          = new FormControl();
  private sltMetodoPago         = new FormControl();
  private sltCreatedAspNetUser  = new FormControl();
  private sltUpdatedAspNetUser  = new FormControl();

  private lstCotizacionesEstatus  : Array<any> = [];
  private lstEmpresas             : Array<any> = [];
  private lstEstados              : Array<any> = [];
  private lstFormasPagos          : Array<any> = [];
  private lstMetodosPagos         : Array<any> = [];
  private lstCreatedAspNetUser    : Array<any> = [];
  private lstUpdatedAspNetUser    : Array<any> = [];

  // COLUMNS
  columns: string[] = ['folio', 'opciones', 'cotizacionEstatus', 'fecha', 'vigencia', 'empresa', 'empresaVendedor', 'cliente', 'clienteContacto', 'formaPago', 'metodoPago', 'total', 'createdAspNetUser', 'createdFecha', 'updatedAspNetUser', 'updatedFecha'];

  // SEARCH FILTERS
  searchFilters : Array<any> = [{field : 'Folio'},
                                {field : 'EmpresaVendedorNombreCompleto'},
                                {field : 'TerminosCondiciones'},
                                {field : 'Plazo'}];
  
  dataSource : MatTableDataSource<any>;

  private objPrivilegies : any;

  constructor(private objCotizacionesService : CotizacionesService,
              private dialog: MatDialog,
              private toastr: ToastrService,
              private datePipe : DatePipe,
              private objRouter : Router,
              private _bottomSheet: MatBottomSheet) { }

  ngOnInit() {
    this.objPrivilegies = Sessions.getItem('privilegies');
    this.initialization();
    this.dataSource = new MatTableDataSource<any>();
  }

  ngAfterViewInit() {
    this.build();
  }

  // EVENTOS
  btnFiltros_clickEvent() {
    
    var objData : any =
    {
      lstCotizacionesEstatus  : this.lstCotizacionesEstatus,
      lstEmpresas             : this.lstEmpresas,
      lstEstados              : this.lstEstados,
      lstFormasPagos          : this.lstFormasPagos,
      lstMetodosPagos         : this.lstMetodosPagos,
      lstCreatedAspNetUser    : this.lstCreatedAspNetUser,
      lstUpdatedAspNetUser    : this.lstUpdatedAspNetUser,

      // FORM CONTROL
      txtDateDesde          : this.txtDateDesde.value,
      txtDateHasta          : this.txtDateHasta.value,
      sltCotizacionEstatus  : this.sltCotizacionEstatus.value,
      sltEmpresa            : this.sltEmpresa.value,
      sltEstado             : this.sltEstado.value,
      sltFormaPago          : this.sltFormaPago.value,
      sltMetodoPago         : this.sltMetodoPago.value,
      sltCreatedAspNetUser  : this.sltCreatedAspNetUser.value,
      sltUpdatedAspNetUser  : this.sltUpdatedAspNetUser.value,
    }

    var bottomSheet = this._bottomSheet.open(CotizacionesIndexFiltrosComponent, {
      data : objData
    });

    bottomSheet.instance.onChange.subscribe((item: CotizacionesIndexFiltrosComponent) => {

      this.txtDateDesde.setValue(item.txtDateDesde.value);
      this.txtDateHasta.setValue(item.txtDateHasta.value);

      this.sltCotizacionEstatus = item.sltCotizacionEstatus;
      this.sltEmpresa           = item.sltEmpresa;
      this.sltEstado            = item.sltEstado;
      this.sltFormaPago         = item.sltFormaPago;
      this.sltMetodoPago        = item.sltMetodoPago;
      this.sltCreatedAspNetUser = item.sltCreatedAspNetUser;
      this.sltUpdatedAspNetUser = item.sltUpdatedAspNetUser;

      this.sltFilter.toArray()[0].value = this.sltCotizacionEstatus.value;
      this.sltFilter.toArray()[1].value = this.sltEmpresa.value;
      this.sltFilter.toArray()[2].value = this.sltEstado.value;
      this.sltFilter.toArray()[3].value = this.sltFormaPago.value;
      this.sltFilter.toArray()[4].value = this.sltMetodoPago.value;
      this.sltFilter.toArray()[5].value = this.sltCreatedAspNetUser.value;
      this.sltFilter.toArray()[6].value = this.sltUpdatedAspNetUser.value;

      this.build();
      
    });
  }

  btnDetalles_clickEvent(element : any) {
    // const dialogRef = this.dialog.open(ClientesIndexContactosComponent, {
    //   width     : '99%',
    //   height    : '99%',
    //   maxWidth  : '1400px',
    //   maxHeight : '700px'
    // });

    // dialogRef.componentInstance.objData = element;
  }

  btnAprobarCancelar_clickEvent(element : any) {
    const dialogRef = this.dialog.open(CotizacionesIndexAprobarCancelarComponent, {
      width     : '99%',
      height    : '99%',
      maxWidth  : '600px',
      maxHeight : '380px'
    });

    dialogRef.componentInstance.objData = element;

    dialogRef.componentInstance.onChange.subscribe(() => {
      this.build();
    });
  }

  btnEmail_clickEvent(element : any) {
    const dialogRef = this.dialog.open(CotizacionesIndexEmailComponent, {
      width     : '99%',
      height    : '99%',
      maxWidth  : '600px',
      maxHeight : '450px'
    });

    dialogRef.componentInstance.objCotizacion = element;
  }

  btnDuplicar_clickEvent(element : any) {
    
    const dialogRef = this.dialog.open(CotizacionesIndexDuplicarComponent, {
      width     : '99%',
      height    : '99%',
      maxWidth  : '1200px',
      // maxHeight : '500px'
    });

    dialogRef.componentInstance.objData = element;

    dialogRef.componentInstance.onChange.subscribe(() => {
      this.build();
    });

  }

  btnImprimir_clickEvent(element : any) {
    let url = this.objRouter.createUrlTree(['/api/Cotizaciones/File/Cotizacion/', element.idCotizacion]);

    window.open(url.toString(), '_blank');
  }

  btnDelete_clickEvent(element : any) {
    
    this.objDialogs.Confirm("Eliminar", "¿Eliminar cotización "+ element.folio +"?", "Eliminar", "Cancelar", ()=> {
      this.delete(element.idCotizacion);
    });

  }

  // METODOS
  private initialization() : void {

    this.objCotizacionesService.index()
    .subscribe( objResponse => {

      if(objResponse.session) {

        if(objResponse.action) {

          this.lstCotizacionesEstatus = objResponse.result.cotizacionesEstatus;
          this.lstEmpresas            = objResponse.result.empresas;
          this.lstEstados             = objResponse.result.estados;
          this.lstFormasPagos         = objResponse.result.formasPagos;
          this.lstMetodosPagos        = objResponse.result.metodosPagos;
          this.lstCreatedAspNetUser   = objResponse.result.usuarios;
          this.lstUpdatedAspNetUser   = objResponse.result.usuarios;

        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }

      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }
      
    }, error => {

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
  }
  
  private build() : void {

    let varArgs : any = {
      search              : Globals.validValue(this.txtSearch.value) ? this.txtSearch.value : "",
      searchFilters       : this.searchFilters,
      filters             : this.filters(),
      dateFrom            : Globals.validValue(this.txtDateDesde.value) ? this.datePipe.transform(this.txtDateDesde.value, 'yyyy-MM-dd') : "",
      dateTo              : Globals.validValue(this.txtDateHasta.value) ? this.datePipe.transform(this.txtDateHasta.value, 'yyyy-MM-dd') : "",
      dateOptions         : [{field : "Fecha"}],
      columns             : [],
      persistenceColumns  : [],
      length              : !Globals.validValue(this.paginator.pageSize) ? 15 : this.paginator.pageSize,
      page                : this.paginator.pageIndex + 1
    };

    Wait.show(this.tableContainer, "Cargando datos...");

    this.objCotizacionesService.dataSource(varArgs)
    .subscribe( objResponse => {

      if(objResponse.session) {

        if(objResponse.action) {

          this.lstCotizaciones      = objResponse.result.rows;

          this.dataSource           = new MatTableDataSource(this.lstCotizaciones);
          this.paginator.pageIndex  = objResponse.result.page - 1;
          this.paginator.pageSize   = objResponse.result.length;
          this.paginator.length     = objResponse.result.count;
          this.index                = this.paginator.pageIndex * this.paginator.pageSize + 1;

        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }

      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }
      
      Wait.hide(this.tableContainer);
      
    }, error => {
      Wait.hide(this.tableContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
    
  }

  private filters() {
    let lstFilters : Array<any> = [];

    if(Globals.validValue(this.sltFilter))
    {
      this.sltFilter.forEach(itemFilter => {

        if(Globals.validValue(itemFilter.value))
        {
          var filter : any = {
            field           : itemFilter.id,
            value           : itemFilter.value
          };

          lstFilters.push(filter);
        }

      })
    }

    return lstFilters;
  }

  private borrarFiltros() : void {
    this.txtSearch.setValue("");
    this.txtDateDesde.setValue("");
    this.txtDateHasta.setValue("");
    this.sltCotizacionEstatus.setValue("");
    this.sltEmpresa.setValue("");
    this.sltEstado.setValue("");
    this.sltFormaPago.setValue("");
    this.sltMetodoPago.setValue("");
    this.sltCreatedAspNetUser.setValue("");
    this.sltUpdatedAspNetUser.setValue("");
    
    this.paginator.pageIndex  = 0;
    this.paginator.pageSize   = 15;

    this.sltFilter.forEach(itemFilter => {
      itemFilter.value = "";
    });
    
    this.build();
  }

  private delete(idCotizacion : any) : void {
    
    Wait.show(this.tableContainer, "Eliminando...")

    let objCotizacionDelete : any =
    {
      idCotizacion : idCotizacion
    };

    this.objCotizacionesService.delete(objCotizacionDelete)
    .subscribe( objResponse => {
    
      if(objResponse.session) {

        if(objResponse.action) {
          
          this.build();
          this.objDialogs.Success(objResponse.title, objResponse.message);
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      Wait.hide(this.tableContainer);

    }, error => {

      Wait.hide(this.tableContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
  }

}
