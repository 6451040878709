import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ServiciosTiposService } from 'src/app/services/serviciosTipos/serviciosTipos.service';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { Wait } from 'src/app/ui/wait/Wait';

@Component({
  selector: 'comp-servicios-tipos-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css']
})
export class ServiciosTiposCreateComponent implements OnInit {

  @ViewChild('mainContainer', {static: false})  mainContainer : ElementRef;
  @ViewChild('ngForm', {static: false})         ngForm        : FormGroupDirective;

  private objDialogs  : Dialogs = new Dialogs();
  private form        : FormGroup;

  constructor(private objServiciosTiposService : ServiciosTiposService,
              private toastr: ToastrService,
              private router: Router) { }

  ngOnInit() {

    this.form = new FormGroup({
      'clave'           : new FormControl(null, [Validators.required]),
      'name'            : new FormControl(null, [Validators.required]),
      'descripcion'     : new FormControl(null, [Validators.required]),
      'precioUnitario'  : new FormControl(null, [Validators.required])
    });

  }

  //EVENTOS
  btnGuardar_clickEvent() {
    
    if(this.form.invalid) {
      this.toastr.warning('Datos incompletos en el formulario', 'Formulario', { timeOut: 2000 });
      this.ngForm.onSubmit(undefined);
      return;
    }

    this.store();

  }

  // METODOS
  private store() : void {

    Wait.show(this.mainContainer, "Guardando...");

    let objServicioTipoStore : any =
    {
      clave           : this.form.controls["clave"].value,
      name            : this.form.controls["name"].value,
      descripcion     : this.form.controls["descripcion"].value,
      precioUnitario  : this.form.controls["precioUnitario"].value
    };

    this.objServiciosTiposService.store(objServicioTipoStore)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {

          this.objDialogs.Success(objResponse.title, objResponse.message, null, ()=> {
            this.router.navigate(["/dashboard/refresh", "servicios/create"]);
          });
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      Wait.hide(this.mainContainer);

    }, error => {

      Wait.hide(this.mainContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

}
