import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { MatButton, MatDialogRef, MatCheckboxChange } from '@angular/material';
import { FormGroupDirective, FormGroup, FormControl, Validators } from '@angular/forms';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { ClientesService } from 'src/app/services/clientes/clientes.service';
import { ToastrService } from 'ngx-toastr';
import { Wait } from 'src/app/ui/wait/Wait';

@Component({
  selector: 'comp-clientes-index-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class ClientesIndexEditComponent implements OnInit, AfterViewInit {

  @ViewChild('mainContainer', {static:false}) mainContainer : ElementRef;
  @ViewChild('btnGuardar', {static:false})    btnGuardar    : MatButton;
  @ViewChild('btnCerrar', {static:false})     btnCerrar     : MatButton;
  @ViewChild('ngFormGroup', {static:false})   ngFormGroup   : FormGroupDirective;

  @Output('onChange') onChange : EventEmitter<any> = new EventEmitter<any>();
  
  private boolGuardar : boolean = false;
  private objDialogs  : Dialogs = new Dialogs();
  private formGroup   : FormGroup;

  private lstEstados  : Array<any> = [];

  public objData : any = null;

  constructor(private objDialogRef : MatDialogRef<ClientesIndexEditComponent>, 
              private objClientesService : ClientesService,
              private toastr: ToastrService) { }

  ngOnInit() {
    this.formGroup = new FormGroup({
      'codigo'        : new FormControl(null, [Validators.required]),
      'name'          : new FormControl(null, [Validators.required]),
      'razonSocial'   : new FormControl(null, [Validators.required]),
      'rfc'           : new FormControl(null),
      'telefono'      : new FormControl(null),
      'celular'       : new FormControl(null),
      'email'         : new FormControl(null),
      'direccion'     : new FormControl(null),
      'ciudad'        : new FormControl(null),
      'estado'        : new FormControl(null),
      'codigoPostal'  : new FormControl(null)
    });

    this.formGroup.controls["codigo"].setValue(this.objData.codigo);
    this.formGroup.controls["name"].setValue(this.objData.name);
    this.formGroup.controls["razonSocial"].setValue(this.objData.razonSocial);
    this.formGroup.controls["rfc"].setValue(this.objData.rfc);
    this.formGroup.controls["telefono"].setValue(this.objData.telefono);
    this.formGroup.controls["celular"].setValue(this.objData.celular);
    this.formGroup.controls["email"].setValue(this.objData.email);
    this.formGroup.controls["direccion"].setValue(this.objData.direccion);
    this.formGroup.controls["ciudad"].setValue(this.objData.ciudad);
    this.formGroup.controls["estado"].setValue(this.objData.idEstado);
    this.formGroup.controls["codigoPostal"].setValue(this.objData.codigoPostal);
  }

  ngAfterViewInit() {
    this.create();
  }

  // EVENTOS
  close()
  {
    if(!this.boolGuardar)
    {
      this.objDialogRef.close();
    }
  }

  btnGuardar_clickEvent()
  {
    if(this.formGroup.invalid) {
      this.toastr.warning('Datos incompletos en el formulario', 'Formulario', { timeOut: 2000 });
      this.ngFormGroup.onSubmit(undefined);
      return;
    }
    
    this.update();
  }

  // METODOS
  private loading(value : boolean) {
    if(value) {

      Wait.show(this.mainContainer, "Guardando...");
      this.btnGuardar.disabled        = true;
      this.btnCerrar.disabled         = true;
      this.boolGuardar                = true;
      this.objDialogRef.disableClose  = true;

    } else{

      Wait.hide(this.mainContainer);
      this.btnGuardar.disabled        = false;
      this.btnCerrar.disabled         = false;
      this.boolGuardar                = false;
      this.objDialogRef.disableClose  = false;

    }
  }

  private create() : void {

    Wait.show(this.mainContainer, "Espere por favor...");

    this.objClientesService.create()
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {

          this.lstEstados = objResponse.result.estados;
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      Wait.hide(this.mainContainer);

    }, error => {

      Wait.hide(this.mainContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

  private update() : void {

    this.loading(true);

    let objClienteUpdate : any =
    {
      idCliente     : this.objData.idCliente,
      codigo        : this.formGroup.controls["codigo"].value,
      name          : this.formGroup.controls["name"].value,      
      razonSocial   : this.formGroup.controls["razonSocial"].value,
      rfc           : this.formGroup.controls["rfc"].value,
      telefono      : this.formGroup.controls["telefono"].value,
      celular       : this.formGroup.controls["celular"].value,
      email         : this.formGroup.controls["email"].value,
      direccion     : this.formGroup.controls["direccion"].value,
      ciudad        : this.formGroup.controls["ciudad"].value,
      idEstado      : this.formGroup.controls["estado"].value,
      codigoPostal  : this.formGroup.controls["codigoPostal"].value
    };

    this.objClientesService.update(objClienteUpdate)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {
          
          this.onChange.emit(objResponse.result);
          this.objDialogs.Success(objResponse.title, objResponse.message);
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      this.loading(false);

    }, error => {

      this.loading(false);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

}
