import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatButton, MatDialogRef } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { ClientesContactosService } from 'src/app/services/clientesContactos/clientesContactos.service';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { Wait } from 'src/app/ui/wait/Wait';

@Component({
  selector: 'app-clientes-contactos-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css']
})
export class ClientesContactosCreateComponent implements OnInit {

  @ViewChild('mainContainer', {static:false}) mainContainer : ElementRef;
  @ViewChild('btnGuardar', {static:false})    btnGuardar    : MatButton;
  @ViewChild('btnCerrar', {static:false})     btnCerrar     : MatButton;
  @ViewChild('ngFormGroup', {static:false})   ngFormGroup   : FormGroupDirective;

  @Output('onChange') onChange : EventEmitter<any> = new EventEmitter<any>();
  
  private boolGuardar : boolean = false;
  private objDialogs  : Dialogs = new Dialogs();
  private formGroup   : FormGroup;

  public objData  : any = null;
  public isEdit   : boolean = false;

  constructor(private objDialogRef : MatDialogRef<ClientesContactosCreateComponent>, 
              private objClientesContactosService : ClientesContactosService,
              private toastr: ToastrService) { }

  ngOnInit() {
    
    this.formGroup = new FormGroup({
      'nombreCompleto'  : new FormControl(null, [Validators.required]),
      'telefono'        : new FormControl(null),
      'correo'          : new FormControl(null)
    });

    if(this.isEdit) {
      this.formGroup.controls["nombreCompleto"].setValue(this.objData.nombreCompleto);
      this.formGroup.controls["telefono"].setValue(this.objData.telefono);
      this.formGroup.controls["correo"].setValue(this.objData.correo);
    }

  }

  // EVENTOS
  close()
  {
    if(!this.boolGuardar)
    {
      this.objDialogRef.close();
    }
  }

  btnGuardar_clickEvent()
  {
    if(this.formGroup.invalid) {
      this.toastr.warning('Datos incompletos en el formulario', 'Formulario', { timeOut: 2000 });
      this.ngFormGroup.onSubmit(undefined);
      return;
    }
    
    if(this.isEdit)
    {
      this.update();
    } else {
      this.store();
    }
  }

  // METODOS
  private loading(value : boolean) {
    if(value) {

      Wait.show(this.mainContainer, "Guardando...");
      this.btnGuardar.disabled        = true;
      this.btnCerrar.disabled         = true;
      this.boolGuardar                = true;
      this.objDialogRef.disableClose  = true;

    } else{

      Wait.hide(this.mainContainer);
      this.btnGuardar.disabled        = false;
      this.btnCerrar.disabled         = false;
      this.boolGuardar                = false;
      this.objDialogRef.disableClose  = false;

    }
  }

  private store() : void {

    this.loading(true);

    let objClienteContactoStore : any =
    {
      idCliente       : this.objData.idCliente,
      nombreCompleto  : this.formGroup.controls["nombreCompleto"].value,
      telefono        : this.formGroup.controls["telefono"].value,
      correo          : this.formGroup.controls["correo"].value
    };

    this.objClientesContactosService.store(objClienteContactoStore)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {
          
          this.objDialogRef.close();
          this.onChange.emit(objResponse.result);
          this.objDialogs.Success(objResponse.title, objResponse.message);
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      this.loading(false);

    }, error => {

      this.loading(false);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

  private update() : void {

    this.loading(true);

    let objClienteContactoUpdate : any =
    {
      idClienteContacto : this.objData.idClienteContacto,
      nombreCompleto    : this.formGroup.controls["nombreCompleto"].value,
      telefono          : this.formGroup.controls["telefono"].value,
      correo            : this.formGroup.controls["correo"].value
    };

    this.objClientesContactosService.update(objClienteContactoUpdate)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {

          this.onChange.emit(this);
          this.objDialogs.Success(objResponse.title, objResponse.message);
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      this.loading(false);

    }, error => {

      this.loading(false);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

}
