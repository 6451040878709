import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatButton, MatDialog, MatDialogRef, MatPaginator, MatTableDataSource } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { Globals } from 'src/app/libraries/globals';
import { EmpresasVendedoresService } from 'src/app/services/empresasVendedores/empresasVendedores.service';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { Wait } from 'src/app/ui/wait/Wait';
import { EmpresasIndexVendedoresCreateComponent } from './create/create.component';

@Component({
  selector: 'comp-empresas-index-vendedores',
  templateUrl: './vendedores.component.html',
  styleUrls: ['./vendedores.component.css']
})
export class EmpresasIndexVendedoresComponent implements OnInit {

  @ViewChild('btnCerrar', {static:false})       btnCerrar       : MatButton;
  @ViewChild(MatPaginator, { static: false})    paginator       : MatPaginator;
  @ViewChild('tableContainer', {static: false}) tableContainer  : ElementRef;

  private boolGuardar           : boolean = false;
  private objDialogs            : Dialogs = new Dialogs();
  private index                 : number;
  private lstEmpresasVendedores : Array<any> = [];

  public objData  : any = null;

  // SEARCH
  private txtSearch = new FormControl();

  // COLUMNS
  columns: string[] = ['index', 'opciones', 'nombre', 'apellidoPaterno', 'apellidoMaterno', 'createdAspNetUser', 'createdFecha', 'updatedAspNetUser', 'updatedFecha'];

  // SEARCH FILTERS
  searchFilters : Array<any> = [{field : 'Nombre'}, {field : 'ApellidoPaterno'}, {field : 'ApellidoMaterno'}];
  
  dataSource : MatTableDataSource<any>;

  constructor(private objDialogRef : MatDialogRef<EmpresasIndexVendedoresComponent>, 
              private dialog: MatDialog,
              private objEmpresasVendedoresService : EmpresasVendedoresService,
              private toastr: ToastrService) { }

  ngOnInit() {
    this.dataSource = new MatTableDataSource<any>();
  }

  ngAfterViewInit() {
    this.build();
  }

  // EVENTOS
  close()
  {
    if(!this.boolGuardar)
    {
      this.objDialogRef.close();
    }
  }

  btnNuevo_clickEvent() {
    
    const dialogRef = this.dialog.open(EmpresasIndexVendedoresCreateComponent, {
      width     : '99%',
      height    : '99%',
      maxWidth  : '400px',
      maxHeight : '350px'
    });

    dialogRef.componentInstance.idEmpresa = this.objData.idEmpresa;

    dialogRef.componentInstance.onChange.subscribe(() => {
      this.build();
    });
    
  }

  btnEdit_clickEvent(element : any) {
    
    const dialogRef = this.dialog.open(EmpresasIndexVendedoresCreateComponent, {
      width     : '99%',
      height    : '99%',
      maxWidth  : '400px',
      maxHeight : '350px'
    });

    dialogRef.componentInstance.objData = element;
    dialogRef.componentInstance.isEdit  = true;

    dialogRef.componentInstance.onChange.subscribe(() => {
      this.build();
    });

  }

  btnDelete_clickEvent(element : any) {
    
    this.objDialogs.Confirm("Eliminar", "¿Eliminar vendedor "+ element.nombreCompleto +"?", "Eliminar", "Cancelar", ()=> {
      this.delete(element.idEmpresaVendedor);
    });

  }

  // METODOS
  private loading(value : boolean) {
    if(value) {

      this.btnCerrar.disabled         = true;
      this.boolGuardar                = true;
      this.objDialogRef.disableClose  = true;

    } else{

      this.btnCerrar.disabled         = false;
      this.boolGuardar                = false;
      this.objDialogRef.disableClose  = false;

    }
  }

  private build() : void {

    let varArgs : any = {
      search              : Globals.validValue(this.txtSearch.value) ? this.txtSearch.value : "",
      searchFilters       : this.searchFilters,
      filters             : [],
      dateFrom            : "",
      dateTo              : "",
      dateOptions         : [{field : "CreatedFecha"}],
      columns             : [],
      persistenceColumns  : [],
      length              : !Globals.validValue(this.paginator.pageSize) ? 15 : this.paginator.pageSize,
      page                : this.paginator.pageIndex + 1,
      idEmpresa           : this.objData.idEmpresa
    };

    Wait.show(this.tableContainer, "Cargando datos...");

    this.objEmpresasVendedoresService.dataSource(varArgs)
    .subscribe( objResponse => {

      if(objResponse.session) {

        if(objResponse.action) {

          this.lstEmpresasVendedores  = objResponse.result.rows;

          this.dataSource             = new MatTableDataSource(this.lstEmpresasVendedores);
          this.paginator.pageIndex    = objResponse.result.page - 1;
          this.paginator.pageSize     = objResponse.result.length;
          this.paginator.length       = objResponse.result.count;
          this.index                  = this.paginator.pageIndex * this.paginator.pageSize + 1;

        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }

      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }
      
      Wait.hide(this.tableContainer);
      
    }, error => {
      Wait.hide(this.tableContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
    
  }

  private borrarFiltros() : void {
    this.txtSearch.setValue("");
    this.build();
  }

  private delete(idEmpresaVendedor : any) : void {
    
    Wait.show(this.tableContainer, "Eliminando...");
    this.loading(true);

    let objEmpresaVendedorDelete : any =
    {
      idEmpresaVendedor : idEmpresaVendedor
    };

    this.objEmpresasVendedoresService.delete(objEmpresaVendedorDelete)
    .subscribe( objResponse => {
    
      if(objResponse.session) {

        if(objResponse.action) {
          
          this.build();
          this.objDialogs.Success(objResponse.title, objResponse.message);
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      Wait.hide(this.tableContainer);
      this.loading(false);

    }, error => {

      Wait.hide(this.tableContainer);
      this.loading(false);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
  }

}
