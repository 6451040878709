import { CurrencyPipe } from '@angular/common';
import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatCheckbox, MatDialog, MatSelectChange, MatSlideToggleChange, MatVerticalStepper } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Globals } from 'src/app/libraries/globals';
import { ClientesContactosService } from 'src/app/services/clientesContactos/clientesContactos.service';
import { InputPredictiveService } from 'src/app/services/components/inputPredictive.service';
import { CotizacionesService } from 'src/app/services/cotizaciones/cotizaciones.service';
import { EmpresasVendedoresService } from 'src/app/services/empresasVendedores/empresasVendedores.service';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { Wait } from 'src/app/ui/wait/Wait';

@Component({
  selector: 'comp-cotizaciones-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css']
})
export class CotizacionesCreateComponent implements OnInit {

  @ViewChild('mainContainer', {static: false})  mainContainer : ElementRef;
  @ViewChild('ngForm', {static: false})         ngFormGeneral : FormGroupDirective;
  @ViewChild('stepper', {static: false})        stepper       : MatVerticalStepper;

  @ViewChildren('chkCondicion') chkCondicion : QueryList<MatCheckbox>;

  private objDialogs  : Dialogs = new Dialogs();
  private form        : FormGroup;

  private lstEmpresas                     : Array<any> = [];
  private lstClientes                     : Array<any> = [];
  private lstClientesContactos            : Array<any> = [];
  private lstServiciosTipos               : Array<any> = [];
  private lstFormasPagos                  : Array<any> = [];
  private lstMetodosPagos                 : Array<any> = [];
  private lstEmpresasVendedores           : Array<any> = [];
  private lstCotizacionesCondicionesTipos : Array<any> = [];

  // PREDICTIVE CLIENTE
  private idCliente     : number = 0;

  // VARIABLES PRECIOS
  iva : any = 0.16;

  lstPartidas : Array<any> = []
  lstValid    : Array<boolean> = []

  constructor(private objCotizacionesService : CotizacionesService,
              private objEmpresasVendedoresService : EmpresasVendedoresService,
              private objClientesContactosService : ClientesContactosService,
              private objInputPredictiveService : InputPredictiveService,
              private toastr: ToastrService,
              private router: Router,
              private currencyPipe : CurrencyPipe,
              private dialog : MatDialog) { }

  ngOnInit() {

    this.form = new FormGroup({
      'hasCliente'          : new FormControl(false),
      'cliente'             : new FormControl(null, [Validators.required]),
      'clienteContacto'     : new FormControl(null),
      'empresa'             : new FormControl(null, [Validators.required]),
      'empresaVendedor'     : new FormControl(null, [Validators.required]),
      'vigencia'            : new FormControl(null, [Validators.required]),
      'formaPago'           : new FormControl(null, [Validators.required]),
      'metodoPago'          : new FormControl(null, [Validators.required]),
      'plazo'               : new FormControl(null),
      'terminosCondiciones' : new FormControl(null),
    });

  }

  ngAfterViewInit() {
    this.create();
  }

  //EVENTOS
  btnNuevoCliente_clickEvent() {
    // const dialogRef = this.dialog.open(CapacitacionesCreateClienteComponent, {
    //   width         : '99%',
    //   height        : '99%',
    //   maxWidth      : '600px',
    //   maxHeight     : '350px',
    // });

    // dialogRef.componentInstance.onChange.subscribe(item => {
    //   this.idCliente = item.idCliente;
    //   this.formGeneral.controls["cliente"].setValue(item.name);
    // });
  }

  sltHasCliente_changeEvent(event : MatSlideToggleChange) {
    this.idCliente = 0;
    this.lstClientes = [];
    this.lstClientesContactos = [];

    this.form.controls["cliente"].setValue("");
    this.form.controls["clienteContacto"].setValue("");

    if(event.checked) {
      this.form.controls["cliente"].disable();
      this.form.controls["clienteContacto"].disable();
    } else {
      this.form.controls["cliente"].enable();
      this.form.controls["clienteContacto"].enable();
    }
  }

  btnAddPartida_clickEvent() {
    this.lstPartidas.push({idServicioTipo : 0, clave : "", descripcion : "", precioUnitario : "", cantidad : "", total : 0});
    this.lstValid.push(true);
  }

  btnRemovePartida_clickEvent(index : number) {
    this.lstPartidas.splice(index, 1);
    this.lstValid.splice(index, 1);
  }

  selectedEmpresa_selectedEvent(event : MatSelectChange) {

    this.form.controls["empresaVendedor"].setValue("");
    this.lstEmpresasVendedores  = [];

    if(!Globals.validValue(event.value))
    {
      return;
    }

    this.getEmpresasVendedores(event.value);

  }

  btnGuardar_clickEvent() {
    if(this.form.invalid) {
      this.toastr.warning('Datos incompletos en el formulario', 'Mensaje', { timeOut: 2000 });
      this.ngFormGeneral.onSubmit(undefined);
      this.stepper.selectedIndex = 0;
      return;
    }

    if(this.lstPartidas.length == 0) {
      this.toastr.warning('No se ha agregado ninguna partida', 'Mensaje', { timeOut: 2000 });
      this.stepper.selectedIndex = 1;
      return;
    }

    if(!this.validatePartidas()) {
      this.toastr.warning('Algunas partidas tienen formulario incompleto', 'Mensaje', { timeOut: 2000 });
      this.stepper.selectedIndex = 1;
      return;
    }

    if(this.chkCondicion.filter(x => x.checked).length == 0) {
      this.toastr.warning('Seleccione al menos una condición de venta', 'Mensaje', { timeOut: 2000 });
      this.stepper.selectedIndex = 2;
      return;
    }

    this.store();
  }

  // PREDICTIVE CLIENTE
  txtCliente_keyup(value : string, event : KeyboardEvent) {

    if(event.keyCode == 37 || event.keyCode == 38 || event.keyCode == 39 || event.keyCode == 40) {
      return;
    }

    var txtSearch     = value;
  
    this.lstClientes = [];

    if(!Globals.validStringValue(txtSearch)) {
      return;
    }

    let lstSearchFilters : Array<any> = [];

    lstSearchFilters.push({field : 'Codigo' });
    lstSearchFilters.push({field : 'Name' });
    lstSearchFilters.push({field : 'RazonSocial' });
    lstSearchFilters.push({field : 'RFC' });
    lstSearchFilters.push({field : 'Telefono' });
    lstSearchFilters.push({field : 'Celular' });
    lstSearchFilters.push({field : 'Email' });
    lstSearchFilters.push({field : 'Direccion' });
    lstSearchFilters.push({field : 'Ciudad' });
    lstSearchFilters.push({field : 'CodigoPostal' });
    

    let varArgs  : any = {
      search            : txtSearch,
      searchFilters     : lstSearchFilters,
      filters           : {},
      columns           : {},
      dateFilters       : { dateStart : '2017-01-01', dateEnd : '2018-12-31'}
    };
    
    this.objInputPredictiveService.predictive("Clientes/Predictive", varArgs)
    .subscribe( objResponse => {
      
      if(objResponse.session) {
      
        if(objResponse.action) {

          this.lstClientes = [];

          var lstClientes : Array<any> = objResponse.result.rows;

          if(lstClientes.length > 0) {
            objResponse.result.rows.forEach(item => {
              this.lstClientes.push({ idCliente : item.idCliente, name : item.razonSocial });
            });
          } else {
            this.lstClientes.push({idCliente : 0, name : "No se encontraron resultados..."});
          }

        } else {
          this.lstClientes.push({idCliente : 0, name : objResponse.message});
        }
      } else {
        this.lstClientes.push({idCliente : 0, name : objResponse.message});
      }
      
    }, error => {

      this.lstClientes = [];

      if( error.status == 401) {
        this.lstClientes.push({idCliente : 0, name : "La sesión ha finalizado por inactividad."});
      } else {
        this.lstClientes.push({idCliente : 0, name : "Ocurrio un error inesperado. Póngase en contacto con el administrador."});
      }

    });

  }

  selectedCliente_selectedEvent(event : any) {
    this.idCliente = event.option.id.idCliente;

    if(this.idCliente != 0) {
      this.getClientesContactos(this.idCliente);
    }
  }

  txtCliente_clickEvent() {
    this.idCliente    = 0;
    this.lstClientes  = [];
    this.lstClientesContactos = []
    this.form.controls["cliente"].setValue("");
    this.form.controls["clienteContacto"].setValue("");
  }

  focusOutCliente_function() {
    if(this.idCliente == 0)
    {
      this.form.controls["cliente"].setValue("");
      this.form.controls["clienteContacto"].setValue("");
    }
  }

  // PREDICTIVE SERVICIO TIPO
  txtServicioTipo_keyup(value : string, event : KeyboardEvent, index) {

    if(event.keyCode == 37 || event.keyCode == 38 || event.keyCode == 39 || event.keyCode == 40) {
      return;
    }

    this.lstValid[index] = true;

    var txtSearch     = value;
  
    this.lstServiciosTipos = [];

    if(!Globals.validStringValue(txtSearch)) {
      return;
    }

    let lstSearchFilters : Array<any> = [];

    lstSearchFilters.push({field : 'Clave' });
    lstSearchFilters.push({field : 'Name' });
    lstSearchFilters.push({field : 'Descripcion' });
    

    let varArgs  : any = {
      search            : txtSearch,
      searchFilters     : lstSearchFilters,
      filters           : {},
      columns           : {},
      dateFilters       : { dateStart : '2017-01-01', dateEnd : '2018-12-31'}
    };
    
    this.objInputPredictiveService.predictive("Servicios/Tipos/Predictive", varArgs)
    .subscribe( objResponse => {
      
      this.lstServiciosTipos = [];
      
      if(objResponse.session) {
      
        if(objResponse.action) {

          var arrServiciosTipos : Array<any> = objResponse.result.rows;
          if(arrServiciosTipos.length > 0) {

            objResponse.result.rows.forEach(item => {
              this.lstServiciosTipos.push({ idServicioTipo : item.idServicioTipo, hasTrouble : false, clave : item.clave, name : item.name, descripcion : item.descripcion, precioUnitario : item.precioUnitario });
            });

          } else {
            this.lstServiciosTipos.push({ idServicioTipo : 0, hasTrouble : true, clave : "000", descripcion: "No se encontraron resultados..." });
          }

        } else {
          this.lstServiciosTipos.push({ idServicioTipo : 0,hasTrouble : true, clave : "000", descripcion: objResponse.message });
        }
      } else {
        this.lstServiciosTipos.push({ idServicioTipo : 0, hasTrouble : true, clave : "000", descripcion: objResponse.message });
      }
      
    }, error => {

      this.lstServiciosTipos = [];

      if( error.status == 401) {
        this.lstServiciosTipos.push({ idServicioTipo : 0, hasTrouble : true, clave : "000", descripcion: "La sesión ha finalizado por inactividad." });
      } else {
        this.lstServiciosTipos.push({ idServicioTipo : 0, hasTrouble : true, clave : "000", descripcion: "Ocurrio un error inesperado. Póngase en contacto con el administrador." });
      }

    });

  }

  selectedServicioTipo_selectedEvent(event : any, index : number) {
    this.lstPartidas[index].idServicioTipo  = event.option.id.idServicioTipo;
    this.lstPartidas[index].descripcion     = event.option.id.descripcion;
    this.lstPartidas[index].precioUnitario  = event.option.id.precioUnitario;
    this.lstServiciosTipos = [];
  }

  txtServicioTipo_clickEvent(index : number) {
    this.lstServiciosTipos                  = [];
    this.lstPartidas[index].idServicioTipo  = 0;
    this.lstPartidas[index].clave           = "";
    this.lstPartidas[index].descripcion     = "";
    this.lstPartidas[index].precioUnitario  = 0;
  }

  focusOutServicioTipo_function(index : number) {
    if(this.lstPartidas[index].idServicioTipo == 0)
    {
      this.lstPartidas[index].clave           = "";
      this.lstPartidas[index].descripcion     = "";
      this.lstPartidas[index].precioUnitario  = 0;
    }
  }

  // METODOS
  private getTotalItem(index : number) : any {
    var objPartida = this.lstPartidas[index];

    objPartida.total = objPartida.precioUnitario * objPartida.cantidad;

    return objPartida.total;
  }

  private getSubtotal() : any {
    return this.lstPartidas.map(t => t.total).reduce((acc, value) => acc + value, 0);
  }

  private getTotalIva() : any {
    let total     = this.lstPartidas.map(t => t.total).reduce((acc, value) => acc + value, 0);
    let totalIva  = total * (this.iva / 100);
    return totalIva;
  }

  private getTotal() : any {
    let subtotal  = this.getSubtotal();
    let totalIva  = this.getTotalIva();
    return subtotal + totalIva;
  }

  private validatePartidas() : boolean {
    let valid : boolean = true;

    this.lstPartidas.forEach((item, index) => {
      this.lstValid[index] = true;

      if(item.idServicioTipo == 0)
      {
        valid = false;
        this.lstValid[index] = false;
      }
    });

    return valid;
  }

  private getEmpresasVendedores(idEmpresa : number) : void {
    
    this.form.controls["empresaVendedor"].disable();

    let objEmpresaVendedor : any =
    {
      idEmpresa : idEmpresa
    };

    this.objEmpresasVendedoresService.listByIdEmpresa(objEmpresaVendedor)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {

          this.lstEmpresasVendedores = objResponse.result.empresasVendedores;
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      this.form.controls["empresaVendedor"].enable();

    }, error => {

      this.form.controls["empresaVendedor"].enable();

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
  }

  private getClientesContactos(idCliente : number) : void {
    
    this.form.controls["clienteContacto"].disable();

    let objClienteContacto : any =
    {
      idCliente : idCliente
    };

    this.objClientesContactosService.listByIdCliente(objClienteContacto)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {

          this.lstClientesContactos = objResponse.result.clientesContactos;
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      this.form.controls["clienteContacto"].enable();

    }, error => {

      this.form.controls["clienteContacto"].enable();

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
  }

  private create() : void {
    
    Wait.show(this.mainContainer, "Espere por favor...");

    this.objCotizacionesService.create()
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {

          this.lstEmpresas                      = objResponse.result.empresas;
          this.lstFormasPagos                   = objResponse.result.formasPagos;
          this.lstMetodosPagos                  = objResponse.result.metodosPagos;
          this.lstCotizacionesCondicionesTipos  = objResponse.result.cotizacionesCondicionesTipos;
          this.iva                              = objResponse.result.porcentajeIva;
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      Wait.hide(this.mainContainer);

    }, error => {

      Wait.hide(this.mainContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
    
  }

  private store() : void {

    Wait.show(this.mainContainer, "Guardando...");

    let lstCotizacionesCondiciones : Array<any> = this.chkCondicion.filter(x => x.checked).map(x => x.value);

    let objCotizacionStore : any =
    {
      hasCliente              : this.form.controls["hasCliente"].value, 
      idCliente               : this.idCliente,
      idClienteContacto       : this.form.controls["clienteContacto"].value,
      idEmpresa               : this.form.controls["empresa"].value,
      idEmpresaVendedor       : this.form.controls["empresaVendedor"].value,
      vigencia                : this.form.controls["vigencia"].value,
      idFormaPago             : this.form.controls["formaPago"].value,
      idMetodoPago            : this.form.controls["metodoPago"].value,
      plazo                   : this.form.controls["plazo"].value,
      terminosCondiciones     : this.form.controls["terminosCondiciones"].value,
      subtotal                : this.getSubtotal(),
      iva                     : this.iva,
      totalIva                : this.getTotalIva(),
      total                   : this.getTotal(),
      cotizacionesCondiciones : lstCotizacionesCondiciones,
      partidas                : this.lstPartidas
    };

    this.objCotizacionesService.store(objCotizacionStore)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {

          this.objDialogs.Success(objResponse.title, objResponse.message, null, ()=> {
            this.router.navigate(["/dashboard/refresh", "cotizaciones/create"]);
          });

          let url =  "api/Cotizaciones/File/Cotizacion/" + objResponse.result.idCotizacion;
          window.open(url.toString(), '_blank');
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      Wait.hide(this.mainContainer);

    }, error => {

      Wait.hide(this.mainContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

}
