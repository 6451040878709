import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Sessions } from 'src/app/libraries/sessions';
import { IReturn } from 'src/app/libraries/ireturn';
import { APP_BASE_HREF } from '@angular/common';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EmpresasVendedoresService {

  constructor(private http: HttpClient,  @Inject(APP_BASE_HREF) private baseUrl: string) { }

  dataSource(varArgs : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Empresas/Vendedores/DataSource", varArgs, Sessions.header());
  }

  store(objEmpresaVendedor : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Empresas/Vendedores/Store", objEmpresaVendedor, Sessions.header());
  }

  update(objEmpresaVendedor : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Empresas/Vendedores/Update", objEmpresaVendedor, Sessions.header());
  }

  delete(objEmpresaVendedor : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Empresas/Vendedores/Delete", objEmpresaVendedor, Sessions.header());
  }

  listByIdEmpresa(objEmpresaVendedor : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Empresas/Vendedores/ListByIdEmpresa", objEmpresaVendedor, Sessions.header());
  }

}