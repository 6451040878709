import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { FormGroup, FormControl, Validators, FormGroupDirective } from '@angular/forms';
import { ConfiguracionesService } from 'src/app/services/configuraciones/configuraciones.service';
import { Wait } from 'src/app/ui/wait/Wait';
import { ToastrService } from 'ngx-toastr';
import { MatCheckboxChange } from '@angular/material';

@Component({
  selector: 'content-configuraciones-global',
  templateUrl: './global.component.html',
  styleUrls: ['./global.component.css']
})
export class ConfiguracionesGlobalComponent implements OnInit {

  @ViewChild("mainContainer", {static: false})  mainContainer : ElementRef;
  @ViewChild('ngForm', {static: false})         ngForm        : FormGroupDirective;

  private objDialogs  : Dialogs = new Dialogs();
  private formGroup   : FormGroup;

  constructor(private objConfiguracionesService : ConfiguracionesService, private toastr: ToastrService) { }

  ngOnInit() {

    this.formGroup = new FormGroup({
      'iva'           : new FormControl('', [Validators.required]),
      'emailEnable'   : new FormControl(false),
      'emailSSL'      : new FormControl(false),
      'emailHost'     : new FormControl('', [Validators.required]),
      'emailPort'     : new FormControl('', [Validators.required]),
      'emailUser'     : new FormControl('', [Validators.required]),
      'emailPassword' : new FormControl('', [Validators.required]),
      'emailDisplay'  : new FormControl('', [Validators.required])
    });

  }

  ngAfterViewInit() {
    this.show();
  }

  // EVENTOS
  btnGuardar_clickEvent() {

    if (this.formGroup.invalid) {
      this.toastr.warning('Datos incompletos en el formulario', 'Formulario', { timeOut: 2000 });
      this.ngForm.onSubmit(undefined);
      return;
    }
    
    this.update();
  }

  btnPredeterminado_clickEvent() {
    this.show();
  }

  // METODOS
  private show() : void {
    Wait.show(this.mainContainer, "Cargando información...", "rgba(255,255,255, 1)");

    this.objConfiguracionesService.show()
    .subscribe(objResponse => {

      if(objResponse.session){

        if(objResponse.action){

          Wait.hide(this.mainContainer);
          
          this.formGroup.controls['iva'].setValue(objResponse.result.iva);
          this.formGroup.controls['emailEnable'].setValue(objResponse.result.emailEnable);
          this.formGroup.controls['emailSSL'].setValue(objResponse.result.emailSSL);
          this.formGroup.controls['emailHost'].setValue(objResponse.result.emailHost);
          this.formGroup.controls['emailPort'].setValue(objResponse.result.emailPort);
          this.formGroup.controls['emailUser'].setValue(objResponse.result.emailUser);
          this.formGroup.controls['emailPassword'].setValue(objResponse.result.emailPassword);
          this.formGroup.controls['emailDisplay'].setValue(objResponse.result.emailDisplay);

        } else {
          this.objDialogs.Alert(objResponse.title, objResponse.message);
        }

      }else{
        this.objDialogs.Alert(objResponse.title, objResponse.message);
      }

      Wait.hide(this.mainContainer);

    }, error => {

      Wait.hide(this.mainContainer);

      if( error.status == 401) {
        this.objDialogs.Alert("Error sesión", "La sesión ha finalizado por inactividad.");
      } else {
        this.objDialogs.Alert("Error inesperado", "Ocurrio un error inesperado. Póngase en contacto con el administrador.");
      }

    });
  }

  private update() : void {

    let lstConfiguraciones : Array<any> = [];

    lstConfiguraciones.push({ variable : "IVA_PORCENTAJE", value : this.formGroup.controls['iva'].value });
    lstConfiguraciones.push({ variable : "EMAIL_ENABLE",   value : this.formGroup.controls['emailEnable'].value });
    lstConfiguraciones.push({ variable : "EMAIL_SSL",      value : this.formGroup.controls['emailSSL'].value });
    lstConfiguraciones.push({ variable : "EMAIL_HOST",     value : this.formGroup.controls['emailHost'].value });
    lstConfiguraciones.push({ variable : "EMAIL_PORT",     value : this.formGroup.controls['emailPort'].value });
    lstConfiguraciones.push({ variable : "EMAIL_USER",     value : this.formGroup.controls['emailUser'].value });
    lstConfiguraciones.push({ variable : "EMAIL_PASSWORD", value : this.formGroup.controls['emailPassword'].value });
    lstConfiguraciones.push({ variable : "EMAIL_DISPLAY",  value : this.formGroup.controls['emailDisplay'].value });
    
    Wait.show(this.mainContainer, "Guardando, espere por favor...");

    let objConfiguracion : any =
    {
      configuraciones : lstConfiguraciones
    };

    this.objConfiguracionesService.update(objConfiguracion)
    .subscribe(objResponse => {

      if(objResponse.session){

        if(objResponse.action){

          this.objDialogs.Success(objResponse.title, objResponse.message);

        } else {
          this.objDialogs.Alert(objResponse.title, objResponse.message);
        }

      }else{
        this.objDialogs.Alert(objResponse.title, objResponse.message);
      }

      Wait.hide(this.mainContainer);

    }, error => {

      Wait.hide(this.mainContainer);

      if( error.status == 401) {
        this.objDialogs.Alert("Error sesión", "La sesión ha finalizado por inactividad.");
      } else {
        this.objDialogs.Alert("Error inesperado", "Ocurrio un error inesperado. Póngase en contacto con el administrador.");
      }

    });

  }

}
