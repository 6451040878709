import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatPaginator, MatTableDataSource } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { Globals } from 'src/app/libraries/globals';
import { FormasPagosService } from 'src/app/services/formasPagos/formasPagos.service';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { Wait } from 'src/app/ui/wait/Wait';
import { FormasPagosCreateComponent } from '../create/create.component';

@Component({
  selector: 'comp-formas-pagos-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class FormasPagosIndexComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false})    paginator       : MatPaginator;
  @ViewChild('tableContainer', {static: false}) tableContainer  : ElementRef;

  private lstFormasPagos  : Array<any> = [];
  private objDialogs      : Dialogs = new Dialogs();
  private index           : number;

  // SEARCH
  private txtSearch = new FormControl();

  // COLUMNS
  columns: string[] = ['index', 'opciones', 'clave', 'name', 'createdAspNetUser', 'createdFecha', 'updatedAspNetUser', 'updatedFecha'];

  // SEARCH FILTERS
  searchFilters : Array<any> = [{field : 'Clave'}, {field : 'Name'},];
  
  dataSource : MatTableDataSource<any>;

  constructor(private objFormasPagosService : FormasPagosService,
              private dialog: MatDialog,
              private toastr: ToastrService) { }

  ngOnInit() {
    this.dataSource = new MatTableDataSource<any>();
  }

  ngAfterViewInit() {
    this.build();
  }

  // EVENTOS
  btnNuevo_clickEvent() {
    const dialogRef = this.dialog.open(FormasPagosCreateComponent, {
      width     : '99%',
      height    : '99%',
      maxWidth  : '400px',
      maxHeight : '300px'
    });

    dialogRef.componentInstance.onChange.subscribe(() => {
      this.build();
    });
    
  }

  btnEdit_clickEvent(element : any) {
    
    const dialogRef = this.dialog.open(FormasPagosCreateComponent, {
      width     : '99%',
      height    : '99%',
      maxWidth  : '400px',
      maxHeight : '300px'
    });

    dialogRef.componentInstance.objData = element;
    dialogRef.componentInstance.isEdit  = true;

    dialogRef.componentInstance.onChange.subscribe(() => {
      this.build();
    });

  }

  btnDelete_clickEvent(element : any) {
    
    this.objDialogs.Confirm("Eliminar", "¿Eliminar forma de pago "+ element.name +"?", "Eliminar", "Cancelar", ()=> {
      this.delete(element.idFormaPago);
    });

  }

  // METODOS
  private build() : void {

    let varArgs : any = {
      search              : Globals.validValue(this.txtSearch.value) ? this.txtSearch.value : "",
      searchFilters       : this.searchFilters,
      filters             : [],
      dateFrom            : "",
      dateTo              : "",
      dateOptions         : [{field : "CreatedFecha"}],
      columns             : [],
      persistenceColumns  : [],
      length              : !Globals.validValue(this.paginator.pageSize) ? 15 : this.paginator.pageSize,
      page                : this.paginator.pageIndex + 1
    };

    Wait.show(this.tableContainer, "Cargando datos...");

    this.objFormasPagosService.dataSource(varArgs)
    .subscribe( objResponse => {

      if(objResponse.session) {

        if(objResponse.action) {

          this.lstFormasPagos       = objResponse.result.rows;

          this.dataSource           = new MatTableDataSource(this.lstFormasPagos);
          this.paginator.pageIndex  = objResponse.result.page - 1;
          this.paginator.pageSize   = objResponse.result.length;
          this.paginator.length     = objResponse.result.count;
          this.index                = this.paginator.pageIndex * this.paginator.pageSize + 1;

        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }

      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }
      
      Wait.hide(this.tableContainer);
      
    }, error => {
      Wait.hide(this.tableContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
    
  }

  private borrarFiltros() : void {
    this.txtSearch.setValue("");
    this.build();
  }

  private delete(idFormaPago : any) : void {
    
    Wait.show(this.tableContainer, "Eliminando...")

    let objFormaPagoDelete : any =
    {
      idFormaPago : idFormaPago
    };

    this.objFormasPagosService.delete(objFormaPagoDelete)
    .subscribe( objResponse => {
    
      if(objResponse.session) {

        if(objResponse.action) {
          
          this.build();
          this.objDialogs.Success(objResponse.title, objResponse.message);
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      Wait.hide(this.tableContainer);

    }, error => {

      Wait.hide(this.tableContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
  }

}
