import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatButton, MatChipInputEvent, MatDialogRef } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { CotizacionesService } from 'src/app/services/cotizaciones/cotizaciones.service';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { Wait } from 'src/app/ui/wait/Wait';
import { Globals } from 'src/app/libraries/globals';

@Component({
  selector: 'comp-cotizaciones-index-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.css']
})
export class CotizacionesIndexEmailComponent implements OnInit {

  @ViewChild('mainContainer', {static:false}) mainContainer : ElementRef;
  @ViewChild('btnGuardar', {static:false})    btnGuardar    : MatButton;
  @ViewChild('btnCerrar', {static:false})     btnCerrar     : MatButton;
  
  private boolGuardar : boolean = false;
  private objDialogs  : Dialogs = new Dialogs();
  private formGroup   : FormGroup;

  objCotizacion : any;

  constructor(private objDialogRef : MatDialogRef<CotizacionesIndexEmailComponent>, 
              private objCotizacionesService : CotizacionesService,
              private toastr: ToastrService) { }

  ngOnInit() {
    this.formGroup = new FormGroup({
      'subject' : new FormControl('', [Validators.required]),
      'body'    : new FormControl('', [Validators.required])
    });

    this.formGroup.controls['subject'].setValue("COTIZACIÓN - " + this.objCotizacion.folio);
    this.formGroup.controls['body'].setValue("ESTIMADO CLIENTE, \n\nPor medio del presente se le hace llegar la cotización solicitada.\n\nQuedo atento a dudas y comentarios.\nSaludos.");

  }

  // EVENTOS
  close()
  {
    if(!this.boolGuardar)
    {
      this.objDialogRef.close();
    }
  }

  btnGuardar_clickEvent()
  {
    if(this.formGroup.valid && this.lstEmails.length > 0)
    {
      this.store();
    } else {
      this.toastr.warning('Datos incompletos en el formulario', 'Formulario', { timeOut: 2000 });
    }
  }

  // CHIPS EMAIL
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  
  lstEmails: any[] = [];

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our email
    if ((value || '').trim()) {
      this.lstEmails.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(fruit: any): void {
    const index = this.lstEmails.indexOf(fruit);

    if (index >= 0) {
      this.lstEmails.splice(index, 1);
    }
  }

  // METODOS
  private loading(value : boolean) {
    if(value) {

      Wait.show(this.mainContainer, "Enviando correo...");
      this.btnGuardar.disabled        = true;
      this.btnCerrar.disabled         = true;
      this.boolGuardar                = true;
      this.objDialogRef.disableClose  = true;

    } else{

      Wait.hide(this.mainContainer);
      this.btnGuardar.disabled        = false;
      this.btnCerrar.disabled         = false;
      this.boolGuardar                = false;
      this.objDialogRef.disableClose  = false;

    }
  }

  private store() : void {

    let objCotizacionSend : any =
    {
      idCotizacion  : this.objCotizacion.idCotizacion,
      subject       : this.formGroup.controls["subject"].value,
      body          : this.formGroup.controls["body"].value,
      lstEmails     : this.lstEmails,
    };

    this.loading(true);

    this.objCotizacionesService.sendEmail(objCotizacionSend)
    .subscribe( objResponse => {
      
        if(objResponse.session) {

          if(objResponse.action) {
            
            this.objDialogRef.close();
            this.objDialogs.Success("Envío exitoso", "Correo enviado correctamente");
            
          } else {
            let txtSize = Globals.validStringValue(objResponse.result) ? objResponse.result : "";

            this.objDialogs.Alert(objResponse.title, objResponse.message + txtSize);
          }
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }

        this.loading(false);

    }, error => {

      this.loading(false);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

}
